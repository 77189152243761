<template>
  <div id="app">
    <header>
      <h1>个人网站</h1>
    </header>

    <main>
      <form @submit.prevent="login">
        <label for="username">用户名：</label>
        <input type="text" id="username" v-model="username" required />

        <label for="password">密码：</label>
        <input type="password" id="password" v-model="password" required />

        <button type="submit">登录</button>
      </form>

      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </main>

    <footer>
      <div class="footer-content">
        <p>
          <a
            href="https://beian.miit.gov.cn/"
            target="_blank"
            rel="noopener noreferrer"
          >
            鲁ICP备2024059221号
          </a>
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      username: '',
      password: '',
      errorMessage: '',
    };
  },
  methods: {
    login() {
      // 模拟用户认证，实际中应该发送请求到服务器进行验证
       this.errorMessage = '用户名或密码错误';
    },
  },
};
</script>

<style>
#app {
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

header {
  background-color: #007bff;
  color: white;
  padding: 10px;
}

main {
  margin-top: 20px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

label {
  margin-bottom: 5px;
}

input {
  margin-bottom: 10px;
  padding: 5px;
}

button {
  background-color: #007bff;
  color: white;
  padding: 10px;
  cursor: pointer;
}

.error-message {
  color: red;
  margin-top: 10px;
}

footer {
  text-align: center;
  margin-top: auto; /* Push the footer to the bottom */
  padding-top: 20px; /* Add some padding for better spacing */
}

.footer-content {
  background-color: #f0f0f0; /* Optional: Add a background color for better visibility */
  padding: 10px;
}

footer p {
  margin: 0;
}

footer a {
  color: #007bff; /* Optional: Customize link color */
}
</style>
